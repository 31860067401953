import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ActionIcon, Box, Button, Drawer, LoadingOverlay, MultiSelect, NumberInput,TextInput } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import InputField from "components/fields/InputField";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import "dayjs/locale/fr"
import { BsFillPenFill } from "react-icons/bs";
import { FaFolder} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ServiceService } from "services/service.service";
import { UserService } from "services/user.service";

const PAGE_SIZE = 10;

const ServiceSchema = yup.object().shape({
  nom: yup.string().required('Invalide Nom'),
  prix: yup.number().required('invalide prix'),
  for_users: yup.array(yup.string()).required('Invalid users'),
});

function Services() {

 const [opened, { open, close }] = useDisclosure(false);
  const [openedU, { open:openU, close:closeU }] = useDisclosure(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const navigate = useNavigate();
  const qc = useQueryClient();
  const serviceService = new ServiceService();
  const key = ['services'];
  const {data:services,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => serviceService.getAll() });

  const userService = new UserService();
  const keyu = ['users'];
  const {data:users,isLoading: isLoadingU} = useQuery({ queryKey: keyu, queryFn:() => userService.getAll() });
  const form = useForm({
    initialValues: {
        nom:'',
        prix: 0,
        for_users: [],
    },
    validate: yupResolver(ServiceSchema),
  });
  const formU = useForm({
    initialValues: {
    _id:'', 
    nom:'',
    prix: 0,
    for_users: [],
    },
    validate: yupResolver(ServiceSchema),
  });

  const {mutate:createService,isPending:loadingCreate} = useMutation({
   mutationFn: (data) => serviceService.create(data),
   onSuccess: () => {
    close();
    qc.invalidateQueries(key);
    form.reset();
   }
});

const {mutate:updateService,isPending:loadingUpdate} = useMutation({
 mutationFn:(data) => serviceService.update(data.id, data.data),
 onSuccess: () => {
  closeU();
  qc.invalidateQueries(key);
 }
});

// const {mutate:deleteService,isPending:loadingDelete} = useMutation({
//     mutationFn: (id) => serviceService.delete(id),
//     onSuccess: () => {
//       qc.invalidateQueries(key);
//     }
// });


//   const confirm = (id) => {
//     deleteService(id)
//   };
  
//   const cancel = () => {
//     notifications.show("L'action a été annulé !");
//   };

  const onCreate = (values) => {
    createService(values);
  }

  const onUpdate = (values) => {
    const {_id,createdAt,updatedAt,__v,...rest} = values;
    updateService({id: _id,data: rest });
}

const handleUpdate  = (data) => {
  formU.setValues(data);
  openU();
}

  const filtered = (services = []) => {
    return  services?.filter(({ prix,nom }) => {
      if (
        debouncedQuery !== '' &&
        !`${prix}${nom}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
      )
        return false;

      return true;
    })
  }

  useEffect(() => {
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setRecords(filtered(services).slice(from, to) ?? []);
    }, [page,services,debouncedQuery]);

  return (
    <div>
    <LoadingOverlay
       visible={isLoadingF || isLoadingU}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
   
    <div className="mt-5">
   
      <WeeklyRevenue add={<div className="flex space-x-2">
        <Button bg="#22C55E" leftSection={<AiOutlinePlus className="h-6 w-6 text-white"/>} onClick={open} >AJOUTER</Button>
      </div>}>
      <>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
    { accessor: 'nom',textAlign: 'center', }, 
    {
      accessor: 'prix',
      textAlign: 'center',
    },
      {
        accessor: 'Pour',
        textAlign: 'center',
        render:({for_users}) => for_users.map(id => users?.find(u => u._id === id))?.map(e => `${e?.prenom} ${e?.nom}`).join(',')
      },
    {
      accessor: 'actions',
      title: <Box mr={6}>actions</Box>,
      textAlign: 'center',
      render: (rowData) => (
          <div className="flex items-center justify-center space-x-1">
        <ActionIcon bg='white' onClick={() => navigate(rowData._id)}>
        <FaFolder className="text-blue-500"/>
        </ActionIcon>
        <ActionIcon bg='white' onClick={() => handleUpdate(rowData)}>
        <BsFillPenFill className="text-green-500"/>
        </ActionIcon>
       </div>
      ),
    },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoadingF}
    totalRecords={filtered(services)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => setPage(p)}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
  />
      </>
      
      </WeeklyRevenue>
    </div>


    <Drawer opened={opened} onClose={close} title="CREATION D'UN SERVICE">
 <LoadingOverlay
       visible={loadingCreate}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={form.onSubmit(onCreate)}>
      <TextInput
      withAsterisk
      label="NOM"
      {...form.getInputProps('nom')}
       />
         <NumberInput
      withAsterisk
      label="PRIX"
      {...form.getInputProps('prix')}
       />
    <MultiSelect
      withAsterisk
      label="POUR LES UTILISATEURS"
      {...form.getInputProps('for_users')}
      data={users?.filter(u => u?.role.includes('controleur')).map(e => ({value:e._id,label: `${e?.prenom} ${e?.nom}`}))}
     />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">SAUVEGARDER</Button>
     </div>
     
   </form>
 </Drawer>
 
 <Drawer position="right" opened={openedU} onClose={closeU} title="MODIFICATION D'UN SERVICE">
 <LoadingOverlay
       visible={loadingUpdate}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={formU.onSubmit(onUpdate)}>
   <TextInput
      withAsterisk
      label="NOM"
      {...formU.getInputProps('nom')}
       />
         <NumberInput
      withAsterisk
      label="PRIX"
      {...formU.getInputProps('prix')}
       />
    <MultiSelect
      withAsterisk
      label="POUR LES UTILISATEURS"
      {...formU.getInputProps('for_users')}
      data={users?.filter(u => u?.role.includes('controleur')).map(e => ({value:e._id,label: `${e?.prenom} ${e?.nom}`}))}
     />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">SAUVEGARDER</Button>
     </div>
     
   </form>
 </Drawer>
  </div>
  )
}

export default Services