import { Box, Button, Group, LoadingOverlay, MultiSelect, NumberFormatter, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery} from '@tanstack/react-query';
import { OperationService } from 'services/operation.service';
import { useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { DataTable } from 'mantine-datatable';
import InputField from 'components/fields/InputField';
import { add, format, isAfter, isBefore } from 'date-fns';
import { FaSearch, FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import { TbSum } from "react-icons/tb";
import { DatePicker } from '@mantine/dates';
import fr from 'dayjs/locale/fr';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import Card from 'components/card';

const PAGE_SIZE = 15;

function Retraits() {
    const [sortStatus, setSortStatus] = useState({
        columnAccessor: 'type',
        direction: 'asc',
      });
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [dateSearchRange, setDateSearchRange] = useState();
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [selectedResponsables, setSelectedResponsables] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [bodyRef] = useAutoAnimate();
    const operationService = new OperationService();
    const keyD = ['all_retraits']
    const {data:operations,isLoading:isLoadingD} = useQuery({
        queryKey: keyD,
        queryFn: () => operationService.getAllRetraits(),
     });

     const responsables = useMemo(() => {
        const responsables = new Set(operations?.map((e) => `${e.responsable.prenom} ${e.responsable.nom}`));
        return [...responsables];
      }, [operations]);

      const services = useMemo(() => {
        const services = new Set(operations?.map((e) => e.service.nom));
        return [...services];
      }, [operations]);

     const filtered = (operations = []) => {
        return  operations?.filter(({ montant,createdAt,responsable,service,description }) => {
          if (
            debouncedQuery !== '' &&
            !`${montant} ${description}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          )
            return false;
    
            if (
              dateSearchRange &&
              dateSearchRange[0] &&
              dateSearchRange[1] &&
              (isAfter(dateSearchRange[0],createdAt) ||
               isBefore(dateSearchRange[1],createdAt))
            )
              return false;

              if (selectedResponsables.length && !selectedResponsables.some((d) => d === `${responsable.prenom} ${responsable.nom}`)) return false;
              if (selectedServices.length && !selectedServices.some((d) => d === service.nom)) return false;
  
          return true;
        })
      }
    
      useEffect(() => {  
          const from = (page - 1) * PAGE_SIZE;
          const to = from + PAGE_SIZE;
          const data = sortBy(operations, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? (filtered(data).slice(from, to) ?? []).reverse() : filtered(data).slice(from, to) ?? []);
        }, [page,operations,debouncedQuery,selectedResponsables,selectedServices,dateSearchRange,sortStatus]);

  return (
    <div>
    <LoadingOverlay
   visible={isLoadingD}
   zIndex={1000}
   overlayProps={{ radius: 'sm', blur: 2 }}
   loaderProps={{ color: 'blue', type: 'dots' }}
 />
    <Card>
 <div className="p-4">
  <Text fw="bold" c="blue">
  LES SERVICES
 </Text>
  <div className="flex justify-between items-center w-1/2 my-4">
  <div className="w-full">
         <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)} placeholder="Rechercher ..." />
   </div>
 </div>
  <DataTable
columns={[
  
  { accessor: 'Date',textAlign: 'center', render:({createdAt}) => format(createdAt,'dd/MM/yyyy'),
  sortable:true,
  filter: ({ close }) => (
    <Stack>
      <DatePicker
        maxDate={add(new Date(),{days:1})}
        locale={fr}
        type="range"
        value={dateSearchRange}
        onChange={setDateSearchRange}
      />
      <Button
        disabled={!dateSearchRange}
        variant="light"
        onClick={() => {
          setDateSearchRange(undefined);
          close();
        }}
      >
        Pas de filtre
      </Button>
    </Stack>
  ),
  filtering: Boolean(dateSearchRange),
 },
  { accessor: 'heure',textAlign: 'center', render:({createdAt}) => format(createdAt,'HH:mm:ss')}, 
  { accessor: 'Service',textAlign: 'center', render:({service}) => service.nom,sortable:true,
  filter: (
    <MultiSelect
      label="Services"
      description="Tous les services"
      data={services}
      value={selectedServices}
      placeholder="filter par service…"
      onChange={setSelectedServices}
      leftSection={<FaSearch size={16} />}
      clearable
      searchable
    />
  ),
  filtering: selectedServices.length > 0,
}, 
  {
    accessor: 'montant',
    textAlign: 'center',
    render:({montant}) => <NumberFormatter thousandSeparator="." decimalSeparator="," value={montant} suffix=' FCFA' />,
    sortable:true,
    footer: (
      <Group gap="xs" className="flex items-center justify-center">
        <Box mb={-4}>
          <TbSum size={24} />
        </Box>
        <div> <NumberFormatter thousandSeparator="." decimalSeparator="," value={records.reduce((acc,cur) => acc + cur.montant,0)} suffix=' FCFA' /></div>
      </Group>
    ),
  },
  { accessor: 'Par',textAlign: 'center', render:({responsable}) => `${responsable.prenom} ${responsable.nom}`,sortable:true,
  filter: (
    <MultiSelect
      label="Responsables"
      description="Tous les responsables"
      data={responsables}
      value={selectedResponsables}
      placeholder="filter par responsable…"
      onChange={setSelectedResponsables}
      leftSection={<FaSearch size={16} />}
      clearable
      searchable
    />
  ),
  filtering: selectedResponsables.length > 0,
},
]}
records={records}
idAccessor="_id"
fetching={isLoadingD}
totalRecords={filtered(operations)?.length}
recordsPerPage={PAGE_SIZE}
page={page}
onPageChange={setPage}
sortStatus={sortStatus}
onSortStatusChange={setSortStatus}
sortIcons={{
  sorted: <FaSortAlphaDownAlt size={14} />,
  unsorted: <FaSortAlphaDown size={14} />,
}}
borderRadius="lg"
shadow="lg"
horizontalSpacing="xs"
verticalAlign="top"
rowBackgroundColor={({ montant }) => {
    if (montant < 0) return { dark: '#f9a2b9', light: '#f9a2b9' };
  }}
  paginationText={({ totalRecords }) => `${totalRecords} Opérations`}
  bodyRef={bodyRef}
/>
 </div>

  </Card>
</div>
  )
}

export default Retraits