import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ActionIcon, Box, Button, Drawer, LoadingOverlay, MultiSelect, PasswordInput, Popover,TextInput } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import InputField from "components/fields/InputField";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import "dayjs/locale/fr"
import { BsFillPenFill } from "react-icons/bs";
import { FaFolder, FaTrash} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { UserService } from "services/user.service";
import { roles } from "./roles";

const PAGE_SIZE = 10;

const UserSchema = yup.object().shape({
  nom: yup.string().required('Invalide Nom'),
  prenom: yup.string().required('invalide prenom'),
  email: yup.string().required('invalide email'),
  password: yup.string().required('Invalid mot de passe'),
  role: yup.array(yup.string()).required('Invalid roles'),
});


function Users() {

  const [opened, { open, close }] = useDisclosure(false);
  const [openedU, { open:openU, close:closeU }] = useDisclosure(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const navigate = useNavigate();
  const qc = useQueryClient();
  const userService = new UserService();
  const key = ['users'];
  const {data:users,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => userService.getAll() });

  const form = useForm({
    initialValues: {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        role: [],
    },
    validate: yupResolver(UserSchema),
  });
  const formU = useForm({
    initialValues: {
    _id:'', 
    nom: '',
    prenom: '',
    email: '',
    role: [],
    },
    validate: yupResolver(UserSchema),
  });

  const {mutate:createUser,isPending:loadingCreate} = useMutation({
   mutationFn: (data) => userService.create(data),
   onSuccess: () => {
    close();
    qc.invalidateQueries(key);
    form.reset();
   }
});

const {mutate:updateUser,isPending:loadingUpdate} = useMutation({
 mutationFn:(data) => userService.update(data.id, data.data),
 onSuccess: () => {
  closeU();
  qc.invalidateQueries(key);
 }
});

const {mutate:deleteUser,isPending:loadingDelete} = useMutation({
    mutationFn: (id) => userService.delete(id),
    onSuccess: () => {
      qc.invalidateQueries(key);
    }
});


  const confirm = (id) => {
    deleteUser(id)
  };
  
  const cancel = () => {
    notifications.show("L'action a été annulé !");
  };

  const onCreate = (values) => {
    createUser(values);
  }

  const onUpdate = (values) => {
    const {_id,createdAt,updatedAt,__v,tel,...rest} = values;
    updateUser({id: _id,data: rest });
}

const handleUpdate  = (data) => {
  formU.setValues(data);
  openU();
}

  const filtered = (users = []) => {
    return  users?.filter(({ prenom,nom }) => {
      if (
        debouncedQuery !== '' &&
        !`${prenom}${nom}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
      )
        return false;

      return true;
    })
  }

  useEffect(() => {
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setRecords(filtered(users).slice(from, to) ?? []);
    }, [page,users,debouncedQuery]);

  return (
    <div>
    <LoadingOverlay
       visible={isLoadingF || loadingDelete}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
   
    <div className="mt-5">
   
      <WeeklyRevenue add={<div className="flex space-x-2">
        <Button bg="#22C55E" leftSection={<AiOutlinePlus className="h-6 w-6 text-white"/>} onClick={open} >AJOUTER</Button>
      </div>}>
      <>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
    { accessor: 'prenom',textAlign: 'center', }, 
    {
      accessor: 'nom',
      textAlign: 'center',
    },
    {
        accessor: 'email',
        textAlign: 'center',
      },
      {
        accessor: 'role',
        textAlign: 'center',
        render:({role}) => role.join(',')
      },
    {
      accessor: 'actions',
      title: <Box mr={6}>actions</Box>,
      textAlign: 'center',
      render: (rowData) => (
          <div className="flex items-center justify-center space-x-1">
        <ActionIcon bg='white' onClick={() => navigate(rowData._id)}>
        <FaFolder className="text-blue-500"/>
        </ActionIcon>
        <ActionIcon bg='white' onClick={() => handleUpdate(rowData)}>
        <BsFillPenFill className="text-green-500"/>
        </ActionIcon>
        <Popover width={200} position="bottom" withArrow shadow="md">
    <Popover.Target>
    <ActionIcon bg='white'>
      <FaTrash className="text-red-500" />
    </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown>
     <div className="flex flexcol">
      <div className="flex-1">Etes-vous sûr de vouloir supprimer?</div>
      <div className="flex-1 flex justify-between">
        <Button variant="outline" color="red" onClick={() => confirm(rowData?._id)}>Oui</Button>
        <Button variant="outline" color="green" onClick={cancel}>Non</Button>
      </div>
     </div>
    </Popover.Dropdown>
  </Popover>
       </div>
      ),
    },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoadingF}
    totalRecords={filtered(users)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => setPage(p)}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
  />
      </>
      
      </WeeklyRevenue>
    </div>


    <Drawer opened={opened} onClose={close} title="CREATION D'UN UTILISATEUR">
 <LoadingOverlay
       visible={loadingCreate}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={form.onSubmit(onCreate)}>
      <TextInput
      withAsterisk
      label="PRENOM"
      {...form.getInputProps('prenom')}
       />
         <TextInput
      withAsterisk
      label="NOM"
      {...form.getInputProps('nom')}
       />
        <TextInput
      withAsterisk
      label="EMAIL"
      {...form.getInputProps('email')}
       />
        <PasswordInput
      withAsterisk
      label="MOT DE PASSE"
      {...form.getInputProps('password')}
       />
    <MultiSelect
      withAsterisk
      label="ROLES"
      {...form.getInputProps('role')}
      data={roles}
     />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">SAUVEGARDER</Button>
     </div>
     
   </form>
 </Drawer>
 
 <Drawer position="right" opened={openedU} onClose={closeU} title="MODIFICATION D'UN UTILISATEUR">
 <LoadingOverlay
       visible={loadingUpdate}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={formU.onSubmit(onUpdate)}>
   <TextInput
      withAsterisk
      label="PRENOM"
      {...formU.getInputProps('prenom')}
       />
         <TextInput
      withAsterisk
      label="NOM"
      {...formU.getInputProps('nom')}
       />
        <TextInput
      withAsterisk
      label="EMAIL"
      {...formU.getInputProps('email')}
       />
    <MultiSelect
      withAsterisk
      label="ROLES"
      {...formU.getInputProps('role')}
      data={roles}
     />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">SAUVEGARDER</Button>
     </div>
     
   </form>
 </Drawer>
  </div>
  )
}

export default Users