import Api from "./Api";
import { Service } from "./Service";

export class CompteService extends Service{
constructor(){
    super(Api,'compte');
}

async byEtudiant(id) {
    return this.api.get(`/${this.ressource}/etudiant/${id}`).then(res => res.data);
  }

  async byCode(code) {
    return this.api.get(`/${this.ressource}/code/${code}`).then(res => res.data);
  }

  async toggleState(id,data) {
    return this.api.patch(`/${this.ressource}/toggle/${id}`,data).then(res => res.data);
  }

}