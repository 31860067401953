import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ActionIcon, Box, LoadingOverlay, Switch} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useDebouncedValue} from "@mantine/hooks";
import { FaFolder, FaSortAlphaDown, FaSortAlphaDownAlt} from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CompteService } from "services/compte.service";
import { format } from "date-fns";
import { QRCodeSVG } from "qrcode.react";
import { sortBy } from "lodash";

const PAGE_SIZE = 10;


function Comptes() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: 'etudiant.nom',
    direction: 'asc',
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const navigate = useNavigate();
  const qc = useQueryClient();
  const compteService = new CompteService();
  const key = ['comptes'];
  const {data:comptes,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => compteService.getAll() });

  const { mutate: toggle,isLoading:isLoadingt} = useMutation(
    {
      mutationFn:  (data) =>  compteService.toggleState(data._id, data.data),
      onSuccess: () => {
        qc.invalidateQueries(key);
      }
    }
  );
  const filtered = (comptes = []) => {
    return  comptes?.filter(({ etudiant }) => {
      if (
        debouncedQuery !== '' &&
        !`${etudiant.ncs}${etudiant.prenom}${etudiant.nom}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
      )
        return false;

      return true;
    })
  }

  useEffect(() => {
     if(debouncedQuery === ''){
      if(searchParams.has('page')){
        setPage(parseInt(searchParams.get('page')));
      }else {
        setPage(1);
      }
     }
     else {
      setPage(1);
     }
      
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      const data = sortBy(comptes, sortStatus.columnAccessor);
      setRecords(sortStatus.direction === 'desc' ? (filtered(data).slice(from, to) ?? []).reverse() : filtered(data).slice(from, to) ?? []);
    }, [searchParams,page,comptes,debouncedQuery,sortStatus]);

    const handleChangeState = (v,id) => {
        const data = {_id: id, data: {is_actif : v}};
        toggle(data);
      };

  return (
    <div>
    <LoadingOverlay
       visible={isLoadingF || isLoadingt}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
   
    <div className="mt-5">
   
      <WeeklyRevenue>
      <>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
      
      { accessor: 'Date de Creation',textAlign: 'center', render:({createdAt}) => format(createdAt,'dd/MM/yyyy') },
      { accessor: 'QR code',textAlign: 'center', render:({code}) => <QRCodeSVG value={code} className="h-8 w-8"/>},
        { accessor: 'etudiant.prenom',textAlign: 'center', }, 
      {
        accessor: 'etudiant.nom',
        textAlign: 'center',
        sortable:true
      },
      {
          accessor: 'Etat',
          textAlign: 'center',
          render:({_id,is_actif}) => <Switch size="xs" checked={is_actif} onChange={(e) => handleChangeState(e.currentTarget.checked,_id)} />
        },
     
    {
      accessor: 'actions',
      title: <Box mr={6}>actions</Box>,
      textAlign: 'center',
      render: (rowData) => (
          <div className="flex items-center justify-center space-x-1">
        <ActionIcon bg='white' onClick={() => navigate(rowData._id)}>
        <FaFolder className="text-blue-500"/>
        </ActionIcon>
       </div>
      ),
    },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoadingF}
    totalRecords={filtered(comptes)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => {
      setSearchParams({'page':p});
      setPage(p);
    }
    }
    rowColor={({ is_actif}) => {
        if (is_actif === false) return 'white';
      }}
      rowBackgroundColor={({ is_actif }) => {
        if (is_actif === false) return 'red';
      }}
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      sortIcons={{
        sorted: <FaSortAlphaDownAlt size={14} />,
        unsorted: <FaSortAlphaDown size={14} />,
      }}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
  />
      </>
      
      </WeeklyRevenue>
    </div>
  </div>
  )
}

export default Comptes