import Api from "./Api";
import { Service } from "./Service";

export class OperationService extends Service{
constructor(){
    super(Api,'operation');
}

async depot(data) {
    return this.api.post(`/${this.ressource}/depot`,data).then(res => res.data);
  }

  async retrait(data) {
    return this.api.post(`/${this.ressource}/retrait`,data).then(res => res.data);
  }

  async retraitByCode(code,data) {
    return this.api.post(`/${this.ressource}/retraitbycode/${code}`,data).then(res => res.data);
  }

  async byCompte(id) {
    return this.api.get(`/${this.ressource}/compte/${id}`).then(res => res.data);
  }

  async byResponsable(id) {
    return this.api.get(`/${this.ressource}/byresponsable/${id}`).then(res => res.data);
  }

  async byService(id) {
    return this.api.get(`/${this.ressource}/byservice/${id}`).then(res => res.data);
  }

  async getAllDepots() {
    return this.api.get(`/${this.ressource}/alldepots`).then(res => res.data);
  }

  async getAllRetraits() {
    return this.api.get(`/${this.ressource}/allretraits`).then(res => res.data);
  }

  async byServiceAndResponsable(ser,res) {
    return this.api.get(`/${this.ressource}/byserviceandresponsable/${ser}/${res}`).then(res => res.data);
  }

}