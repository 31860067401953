import { Popover } from "@mantine/core";


const Dropdown = ({ button, children }) => {
  

  return (
    <Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
       {button}
      </Popover.Target>
      <Popover.Dropdown>
        {children}
      </Popover.Dropdown>
    </Popover>
  );
};

export default Dropdown;
