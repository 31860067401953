import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ActionIcon, Box, Button, Drawer, FileInput, LoadingOverlay,Popover,Textarea,TextInput } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import InputField from "components/fields/InputField";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { yupResolver } from 'mantine-form-yup-resolver';
import * as yup from 'yup';
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import "dayjs/locale/fr"
import { BsFillPenFill } from "react-icons/bs";
import { FaTrash} from "react-icons/fa";
import { PubService } from "services/pub.service";
import { notifications } from "@mantine/notifications";
import { DateInput } from "@mantine/dates";
import dayjs from "dayjs";
import { Image, Progress } from "antd";
import { intervalToDuration } from "date-fns";
import { round } from "lodash";

const PAGE_SIZE = 10;

const PubSchema = yup.object().shape({
    titre: yup.string().required('Invalide Titre'),
    description: yup.string().required('invalide description'),
    debut:yup.date().required('invalid debut!'),
    fin:yup.date().required('invalid fin!'),
  });


function Pubs() {
    const [opened, { open, close }] = useDisclosure(false);
    const [openedU, { open:openU, close:closeU }] = useDisclosure(false);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const qc = useQueryClient();
    const pubService = new PubService();
    const key = ['pubs'];
    const {data:pubs,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => pubService.getAll() });

    const form = useForm({
      initialValues: {
        titre: '',
        description:'',
        image: null,
        debut: new Date(),
        fin: new Date(),
      },
      validate: yupResolver(PubSchema),
    });
    const formU = useForm({
      initialValues: {
        _id:'', 
        titre: '',
        description:'',
        image: null,
        debut: new Date(),
        fin: new Date(),
      },
      validate: yupResolver(PubSchema),
    });
  
    const {mutate:createPub,isPending:loadingCreate} = useMutation({
     mutationFn: (data) => pubService.create(data),
     onSuccess: () => {
      close();
      qc.invalidateQueries(key);
      form.reset();
     }
  });
  
  const {mutate:updatePub,isPending:loadingUpdate} = useMutation({
   mutationFn:(data) => pubService.update(data.id, data.data),
   onSuccess: () => {
    closeU();
    qc.invalidateQueries(key);
   }
  });
  
  const {mutate:deletePub,isPending:loadingDelete} = useMutation({
      mutationFn: (id) => pubService.delete(id),
      onSuccess: () => {
        qc.invalidateQueries(key);
      }
  });
  
  
    const confirm = (id) => {
      deletePub(id)
    };
    
    const cancel = () => {
      notifications.show("L'action a été annulé !");
    };
  
    const onCreate = (values) => {
    if(values.image) {
         const fd = new FormData();
        for (const key in values) {
            if (key === 'image') {
                fd.append(key,values[key],values[key].name);
            } else {
                fd.append(key, values[key]);
            }
    }
    createPub(fd);
    }
    else {
    notifications.show({message:'Selectionnez une image ',color:'orange'});
    }
       
}

  
    const onUpdate = (values) => {
      const {_id,createdAt,updatedAt,__v,...rest} = values;
        const fd = new FormData();
       for (const key in rest) {
           if (key === 'image') {
              if(rest[key] !== null)
               fd.append(key,rest[key],rest[key].name);
           } else {
               fd.append(key, rest[key]);
           }
   }
   updatePub({id:_id,data:fd});
}


  const handleUpdate  = (data) => {
    const {image,...rest} = data;
    rest.debut = new Date(rest.debut);
    rest.fin = new Date(rest.fin);
    formU.setValues(rest);
    openU();
  }
  
    const filtered = (pubs = []) => {
      return  pubs?.filter(({ titre,description }) => {
        if (
          debouncedQuery !== '' &&
          !`${titre}${description}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
        )
          return false;
  
        return true;
      })
    }
  
    useEffect(() => {
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(filtered(pubs).slice(from, to) ?? []);
      }, [page,pubs,debouncedQuery]);

  return (
    <div>
    <LoadingOverlay
       visible={isLoadingF || loadingDelete}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
   
    <div className="mt-5">
   
      <WeeklyRevenue add={<div className="flex space-x-2">
        <Button bg="#22C55E" leftSection={<AiOutlinePlus className="h-6 w-6 text-white"/>} onClick={open} >AJOUTER</Button>
      </div>}>
      <>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
    { accessor: 'titre',textAlign: 'center', }, 
    {
      accessor: 'description',
      textAlign: 'center',
    },
      {
        accessor: 'Image',
        textAlign: 'center',
        render:({image}) => <Image
        width={20}
        src={`${process.env.REACT_APP_BACKEND}/uploads/pubs/${image}`} 
    />
      },
      {
        accessor: 'Expiration',
        textAlign: 'center',
        render:({debut,fin}) => {
            const days = intervalToDuration({
                start: debut,
                end: new Date()
              }).days;
          const total = intervalToDuration({
            start:debut,
            end: fin
          }).days;

          const value = round((days * 100) / total);

          return  <Progress steps={total} percent={value} size="small" />
        
        }
      },
    {
      accessor: 'actions',
      title: <Box mr={6}>actions</Box>,
      textAlign: 'center',
      render: (rowData) => (
          <div className="flex items-center justify-center space-x-1">
        <ActionIcon bg='white' onClick={() => handleUpdate(rowData)}>
        <BsFillPenFill className="text-green-500"/>
        </ActionIcon>
        <Popover width={200} position="bottom" withArrow shadow="md">
    <Popover.Target>
    <ActionIcon bg='white'>
      <FaTrash className="text-red-500" />
    </ActionIcon>
    </Popover.Target>
    <Popover.Dropdown>
     <div className="flex flexcol">
      <div className="flex-1">Etes-vous sûr de vouloir supprimer?</div>
      <div className="flex-1 flex justify-between">
        <Button variant="outline" color="red" onClick={() => confirm(rowData?._id)}>Oui</Button>
        <Button variant="outline" color="green" onClick={cancel}>Non</Button>
      </div>
     </div>
    </Popover.Dropdown>
  </Popover>
       </div>
      ),
    },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoadingF}
    totalRecords={filtered(pubs)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => setPage(p)}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
  />
      </>
      
      </WeeklyRevenue>
    </div>


    <Drawer opened={opened} onClose={close} title="CREATION D'UN PUB">
 <LoadingOverlay
       visible={loadingCreate}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={form.onSubmit(onCreate)}>
      <TextInput
      withAsterisk
      label="TITRE"
      {...form.getInputProps('titre')}
       />
       <Textarea
      label="DESCRIPTION"
      {...form.getInputProps('description')}
       />
       <FileInput
        label="IMAGE"
        {...form.getInputProps('image')}
        accept="image/png,image/jpeg"
       />
       <DateInput
       minDate={new Date()}
      label="DEBUT"
      placeholder="Debut"
      {...form.getInputProps('debut')}
    />
      <DateInput
      minDate={dayjs(new Date()).add(1, 'day').toDate()}
      maxDate={dayjs(new Date()).add(1, 'month').toDate()}
      label="FIN"
      placeholder="Fin"
      {...form.getInputProps('fin')}
    />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">SAUVEGARDER</Button>
     </div>
     
   </form>
 </Drawer>
 
 <Drawer position="right" opened={openedU} onClose={closeU} title="MODIFICATION D'UN PUB">
 <LoadingOverlay
       visible={loadingUpdate}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'bars' }}
     />
   <form onSubmit={formU.onSubmit(onUpdate)}>
   <TextInput
      withAsterisk
      label="TITRE"
      {...formU.getInputProps('titre')}
       />
       <Textarea
      label="DESCRIPTION"
      {...formU.getInputProps('description')}
       />
         <FileInput
        label="IMAGE"
        {...formU.getInputProps('image')}
         accept="image/png,image/jpeg"
       />
       <DateInput
       minDate={new Date()}
      label="DEBUT"
      placeholder="Debut"
      {...formU.getInputProps('debut')}
    />
      <DateInput
      minDate={dayjs(new Date()).add(1, 'day').toDate()}
      maxDate={dayjs(new Date()).add(1, 'month').toDate()}
      label="FIN"
      placeholder="Fin"
      {...formU.getInputProps('fin')}
    />
     <div className="my-5">
         <Button type="submit" bg="#422AFB">SAUVEGARDER</Button>
     </div>
     
   </form>
 </Drawer>
  </div>
  );
}

export default Pubs