import { Suspense } from "react";

const Dashboard = () => {


  return (
    <div>
    <Suspense  fallback={<div>Loading...</div>}>

      <iframe style={{background: '#FFF', border: 'none', borderRadius: 2, boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)', width: '100%', height: '100vh'}} src="https://charts.mongodb.com/charts-project-0-ssjfpqa/embed/dashboards?id=41d4f876-b713-4595-8000-14028f5bb395&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=true&scalingWidth=fixed&scalingHeight=fixed" />
    </Suspense>
    </div>
  );
};

export default Dashboard;
