import { Box, Button, Group, Image, LoadingOverlay,NumberFormatter,Stack, Text } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { OperationService } from 'services/operation.service';
import useScanDetection from 'use-scan-detection';
import { useEffect, useState } from 'react';
import { useAppStore } from 'variables/store';
import { useParams, useSearchParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { DataTable } from 'mantine-datatable';
import InputField from 'components/fields/InputField';
import { add, format, isAfter, isBefore} from 'date-fns';
import { FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';
import { TbSum } from "react-icons/tb";
import { DatePicker } from '@mantine/dates';
import { ServiceService } from 'services/service.service';
import { useDebouncedValue } from '@mantine/hooks';
import useSound from 'use-sound';
import success from './success.mp3';
import error from './error.mp3';
import { notifications } from '@mantine/notifications';
import { useAutoAnimate } from '@formkit/auto-animate/react'
import fr from 'dayjs/locale/fr'
import Card from 'components/card';
import { validate } from 'uuid';


const PAGE_SIZE = 15;

function Controller() {
    const {id} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortStatus, setSortStatus] = useState({
        columnAccessor: 'type',
        direction: 'asc',
      });
      const qc = useQueryClient();
      const [playSuccess] = useSound(success);
      const [playError] = useSound(error);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [dateSearchRange, setDateSearchRange] = useState();
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [bodyRef] = useAutoAnimate();
    const {user} = useAppStore();
    const serviceService = new ServiceService();
    const key = ['services',id];
    const {data:service,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => serviceService.getOne(id) });
    const keyR = ['operation_by_responsable',id];
     const operationService = new OperationService();

     const {mutate:createRetrait,isPending} = useMutation({
        mutationFn: (data) => operationService.retraitByCode(data.code,data.data),
        onSuccess: () => {
         qc.invalidateQueries(keyR);
         playSuccess();
        },
          onError: () => {
            notifications.show({title:"INFORMATIONS",message:"service déja utilisé ou montant insuffisant!!",color:"orange",position: 'top-left',autoClose: 3000,});
            playError();
            },
     });
    const {data:operations,isLoading:isLoadingR} = useQuery({
        queryKey: keyR,
        queryFn: () => operationService.byServiceAndResponsable(id,user?._id),
     });

     useScanDetection({
        onComplete: (codec) => {
            const c = codec.replace(/Shift/gi,"");
            if(validate(c)){
               const code = c;
            const responsable =  {prenom:user?.prenom,nom:user?.nom,_id:user?._id};
            const responsable_id =  user?._id;
            const serv =  {nom:service?.nom,prix:service?.prix,_id:service?._id};
            const service_id =  service?._id;
            const service_token = service?._id;
            const montant = service?.prix;
            createRetrait({code,data:{responsable,responsable_id,service:serv,service_id,montant,service_token}});
            }
          },
      });
  
      const filtered = (operations = []) => {
        return  operations?.filter(({ montant,createdAt,description }) => {
          if (
            debouncedQuery !== '' &&
            !`${montant} ${description}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          )
            return false;
    
            if (
              dateSearchRange &&
              dateSearchRange[0] &&
              dateSearchRange[1] &&
              (isAfter(dateSearchRange[0],createdAt) ||
               isBefore(dateSearchRange[1],createdAt))
            )
              return false;
  
          return true;
        })
      }
    
      useEffect(() => {
         if(debouncedQuery === ''){
          if(searchParams.has('page')){
            setPage(parseInt(searchParams.get('page')));
          }else {
            setPage(1);
          }
         }
         else {
          setPage(1);
         }
          
          const from = (page - 1) * PAGE_SIZE;
          const to = from + PAGE_SIZE;
          const data = sortBy(operations, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? (filtered(data).slice(from, to) ?? []).reverse() : filtered(data).slice(from, to) ?? []);
        }, [searchParams,page,operations,debouncedQuery,dateSearchRange,sortStatus]);
  return (
    <div>
         <LoadingOverlay
       visible={isLoadingF || isLoadingR || isPending}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
     <div className="mb-5">
         <Text fw="bold" fz="h3" c="blue">
         {service?.nom} {service?.prix} FCFA
     </Text>
     </div>
    
    <div className="flex h-full">
    <div className="w-3/12 flex items-center justify-center">
      <Image src="/qrcode.gif" />
    </div>


   <div className="w-9/12">
   <Card>
   <div className="p-4">
     <Text fw="bold" c="blue">
      MES OPERATIONS
     </Text>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
      
      { accessor: 'Date',textAlign: 'center', render:({createdAt}) => format(createdAt,'dd/MM/yyyy'),
      sortable:true,
      filter: ({ close }) => (
        <Stack>
          <DatePicker
            maxDate={add(new Date(),{days:1})}
            locale={fr}
            type="range"
            value={dateSearchRange}
            onChange={setDateSearchRange}
          />
          <Button
            disabled={!dateSearchRange}
            variant="light"
            onClick={() => {
              setDateSearchRange(undefined);
              close();
            }}
          >
            Effacer
          </Button>
        </Stack>
      ),
      filtering: Boolean(dateSearchRange),
     },
      { accessor: 'heure',textAlign: 'center', render:({createdAt}) => format(createdAt,'HH:mm:ss')}, 
      { accessor: 'description',textAlign: 'center'}, 
      {
        accessor: 'montant',
        textAlign: 'center',
        render:({montant}) => <NumberFormatter thousandSeparator="." decimalSeparator="," value={montant} suffix=' FCFA' />,
        sortable:true,
        footer: (
          <Group gap="xs" className="flex items-center justify-center">
            <Box mb={-4}>
              <TbSum size={24} />
            </Box>
            <div> <NumberFormatter thousandSeparator="." decimalSeparator="," value={records.reduce((acc,cur) => acc + cur.montant,0)} suffix=' FCFA' /></div>
          </Group>
        ),
      },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoadingR}
    totalRecords={filtered(operations)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => {
      setSearchParams({'page':p});
      setPage(p);
    }
    }
    sortStatus={sortStatus}
    onSortStatusChange={setSortStatus}
    sortIcons={{
      sorted: <FaSortAlphaDownAlt size={14} />,
      unsorted: <FaSortAlphaDown size={14} />,
    }}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
    bodyRef={bodyRef}
  />
      </div>

      </Card>
      </div>
     </div>
    </div>
  )
}

export default Controller