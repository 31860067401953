import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

import { FaAt, FaGraduationCap, FaPhoneAlt } from 'react-icons/fa';
import { Avatar, Button, Group, LoadingOverlay, Popover, Text } from "@mantine/core";
import { PDFViewer } from '@react-pdf/renderer'
import clsx from "clsx";
import pdfMake from "pdfmake";

import { format } from "date-fns";
import { EtudiantService } from "services/etudiant.service";
import { CompteService } from "services/compte.service";
import { drapeau } from "../default/drapeau";
import { logo } from "../marketplace/components/logo";
import { font } from "../../../vfs_fonts";
import QRGenerator from "./components/QrCodeGenerator";
import Recto from "./components/Recto";
import Verso from "./components/Verso";
import { notifications } from "@mantine/notifications";
pdfMake.vfs = font;


function Etudiant() {
  const qc = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const key = ["loadEtudiant", id];
  const keyI = ["inscription", id];
  const keycompte = ["loadCompteEtudiant", id];
  const etudiantService = new EtudiantService();
  const compteService = new CompteService();

  const { data: etudiant,isLoading } = useQuery({
    queryKey: key,
    queryFn: () => etudiantService.getOne(id),
  });

  const { data: inscription,isLoading:isLoadingI } = useQuery({
    queryKey: keyI,
    queryFn: () => etudiantService.getInscription(id),
  });


  const { data: compte,isLoading:isLoadingC } = useQuery({
    queryKey: keycompte,
    queryFn: () => compteService.byEtudiant(id),
  });
  const { mutate: createC, isPending } = useMutation({
    mutationFn:(data) => compteService.create(data),
    onSuccess: () => {
      qc.invalidateQueries(keycompte);
    }
  });
  

 
  const handleViewCompte = (id) => navigate('/admin/comptes/' + id)
 
  const handleGenAttest = () => {
    const docDefinition = {
      styles: {
        entete: {
            bold: true,
            alignment:'center',
            fontSize:10
        },
        center: {
            alignment:'center',
            fontSize:8,
            bold:true
        },
        left: {
          alignment:'left',
      },
      right: {
        alignment:'right',
    },
        nombre: {
          alignment:'right',
          fontSize:10,
          bold: true
      },
         info: {
          fontSize:6,
      },
        header3: {
            color:"white",
            fillColor: '#73BFBA',
            bold: true,
            alignment:'center',
            fontSize:6,
        },
        header4: {
          color:"white",
          fillColor: '#73BFBA',
          bold: true,
          alignment:'right',
          fontSize:6
      },
        total:{
            color:"white",
            bold: true,
            fontSize:6,
            fillColor:'#73BFBA',
            alignment:'center'
        },
        anotherStyle: {
          italics: true,
          alignment: 'right'
        }
      },
      content:[{
        columns: [
          {
          with: 'auto',
          alignment:'left',
          stack: [
            {text:"REPUBLIQUE DU SENEGAL\n",fontSize: 8,bold: true,alignment:"center"},
            {text:"Un Peuple, Un but, Une Foi\n",fontSize: 8,bold: true,margin:[0,2],alignment:"center"},
            {image:drapeau,width: 40,alignment:"center"},
            {text:"MINISTERE DE L'ENSEIGNEMENT SUPERIEUR DE LA RECHERCHE ET DE L'INNOVATION \n",fontSize: 8,bold: true,margin:[0,2],alignment:"center"},
            {text:"CENTRE REGIONAL DES OEUVRES UNIVERSITAIRES SOCIALES DE ZIGUINCHOR\n",fontSize: 8,bold: true,margin:[0,2],alignment:"center"},
          ]},
          
          {
            with:'auto',
            alignment:'right',
            stack:[
              {image:logo,width: 80,alignment:"center"},
              {text:`Ziguinchor Le : ${format(new Date(),'dd/MM/yyyy')}`,fontSize: 8,bold: true,alignment:"center"},
            ]
            
          }
        ]
      },
      {
        margin: [0,20],
        fillColor:"#48a4fa",
        alignment:'center',
        layout:'noBorders',
        table: {
          widths: [500],
          body: [
            [ {text:`ATTESTATION PROVISOIRE POUR LA CARTE SOCIALE`,fontSize: 16,bold: true,color:'white',margin:[0,4]}],
          ]
        }
      },
    ]
    }
    
      pdfMake.createPdf(docDefinition).open();
  }


  const confirm = () => {
    createC({etudiant:etudiant?._id,password:'123456' })
  };
  
  const cancel = () => {
    notifications.show("L'action a été annulé !");
  };

  return (
    <>
    <LoadingOverlay  visible={isLoading || isLoadingC || isPending || isLoadingI}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }} />
      <section className="w-full flex flex-col justify-center px-6 antialiased ">
      <div className={clsx('w-full p-2', compte?.is_actif === false && 'bg-red-500')}>
      <Group className="space-x-5 h-52">
        {etudiant?.avatar && <Avatar src={`${process.env.REACT_APP_BACKURL_ETUDIANT}/${etudiant.avatar}`} size={150} radius="xl"/>}
        <div>
          <Text size="sm" sx={{ textTransform: 'uppercase' }} weight={700}>
            {etudiant?.prenom}  {etudiant?.nom}
          </Text>
          <Group spacing={10} mt={3}>
            <FaAt stroke={1.5} size={16} />
            <Text size="sm" >
              {etudiant?.email}
            </Text>
          </Group>

          <Group spacing={10} mt={5}>
            <FaPhoneAlt stroke={1.5} size={16} />
            <Text size="sm">
              {etudiant?.telephone}
            </Text>
          </Group>
          <Group spacing={10} mt={5}>
            <FaGraduationCap stroke={1.5} size={16} />
            <Text size="md">
              {inscription?.formation?.nom}
            </Text>
          </Group>
          {!compte && <div className="flex flex-col items-center mt-12 text-center">
            <span className="relative inline-flex w-full md:w-auto">
              <Popover width={200} position="bottom" withArrow shadow="md">
                <Popover.Target>
               
                <Button
                className="btn rounded-md shadow-md text-center py-2"
              >
                Creer son compte
              </Button>
                </Popover.Target>
                <Popover.Dropdown>
                <div className="flex flex-col">
                <div className="flex-1">Etes-vous sûr ?</div>
                <div className="flex-1 flex justify-between">
                    <Button variant="outline" color="red" onClick={confirm}>Oui</Button>
                    <Button variant="outline" color="green" onClick={cancel}>Non</Button>
                </div>
                </div>
                </Popover.Dropdown>
             </Popover>
            </span>
          </div>}
          {compte && <div  className="flex items-center space-x-4 mt-12 text-center">
            
              <Button
                onClick={() => handleViewCompte(compte._id)}
                className="bg-green-400 rounded-md shadow-md text-center py-2"
              >
                Voir les transactions du compte
              </Button>
           

            
              <Button
                onClick={() => handleGenAttest(compte._id)}
                className="bg-blue-500 text-white rounded-md shadow-md text-center py-2"
              >
                Generer attestation
              </Button>
           
          </div>}
        </div>
      </Group>
     
    </div>
     {compte && <div className="bg-slate-50 p-20">

  <div className="hidden">
      <QRGenerator value={compte?.code} documentId="qrcode" />
    </div>
    <div className="flex items-center space-x-2">
      <PDFViewer width={400} height={300}>
            <Recto compte={compte}/>
          </PDFViewer>
          <PDFViewer width={400} height={300}>
            <Verso />
          </PDFViewer>
    </div>
    
     </div>}

      </section>
    </>
  )
}

export default Etudiant