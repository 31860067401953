import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { useQuery } from "@tanstack/react-query";
import { ActionIcon, Avatar, Box, LoadingOverlay} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import InputField from "components/fields/InputField";
import { useEffect, useState } from "react";
import { useDebouncedValue} from "@mantine/hooks";
import { FaFolder, FaSortAlphaDown, FaSortAlphaDownAlt} from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EtudiantService } from "services/etudiant.service";
import { format } from "date-fns";
import { sortBy } from "lodash";

const PAGE_SIZE = 15;


function Etudiants() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: 'nom',
    direction: 'asc',
  });
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebouncedValue(query, 200);
  const navigate = useNavigate();
  const etudiantService = new EtudiantService();
  const key = ['etudiants'];
  const {data:etudiants,isLoading: isLoadingF} = useQuery({ queryKey: key, queryFn:() => etudiantService.getAll() });
  const filtered = (etudiants = []) => {
    return  etudiants?.filter(({ prenom,nom,ncs }) => {
      if (
        debouncedQuery !== '' &&
        !`${ncs}${prenom}${nom}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
      )
        return false;

      return true;
    })
  }

  useEffect(() => {
     if(debouncedQuery === ''){
      if(searchParams.has('page')){
        setPage(parseInt(searchParams.get('page')));
      }else {
        setPage(1);
      }
     }
     else {
      setPage(1);
     }
      
      const from = (page - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      const data = sortBy(etudiants, sortStatus.columnAccessor);
      setRecords(sortStatus.direction === 'desc' ? (filtered(data).slice(from, to) ?? []).reverse() : filtered(data).slice(from, to) ?? []);
    }, [searchParams,page,etudiants,debouncedQuery,sortStatus]);


  return (
    <div>
    <LoadingOverlay
       visible={isLoadingF}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
   
    <div className="mt-5">
   
      <WeeklyRevenue>
      <>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
      
      { accessor: 'Date de Creation',textAlign: 'center', render:({createdAt}) => format(createdAt,'dd/MM/yyyy'),sortable:true },
      { accessor: 'Photo',textAlign: 'center', render:({avatar}) => <Avatar size="md" src={`${process.env.REACT_APP_BACKURL_ETUDIANT}/${avatar}`}/>},
        { accessor: 'prenom',textAlign: 'center', }, 
      {
        accessor: 'nom',
        textAlign: 'center',
        sortable:true
      },
      {
          accessor: 'N° SOCIALE',
          textAlign: 'center',
          render:({ncs}) => ncs,
          sortable:true
        },
     
    {
      accessor: 'actions',
      title: <Box mr={6}>actions</Box>,
      textAlign: 'center',
      render: (rowData) => (
          <div className="flex items-center justify-center space-x-1">
        <ActionIcon bg='white' onClick={() => navigate(rowData._id)}>
        <FaFolder className="text-blue-500"/>
        </ActionIcon>
       </div>
      ),
    },
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoadingF}
    totalRecords={filtered(etudiants)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => {
      setSearchParams({'page':p});
      setPage(p);
    }
    }
    sortStatus={sortStatus}
    onSortStatusChange={setSortStatus}
    sortIcons={{
      sorted: <FaSortAlphaDownAlt size={14} />,
      unsorted: <FaSortAlphaDown size={14} />,
    }}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
  />
      </>
      
      </WeeklyRevenue>
    </div>
  </div>
  )
}

export default Etudiants