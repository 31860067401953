import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { useEffect, useState } from "react";
import { getAuth } from "services/authservice";
import { useQuery } from "@tanstack/react-query";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useAppStore } from "../../variables/store";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import Etudiant from "views/admin/etudiants/etudiant";
import Compte from "views/admin/comptes/compte";
import Controller from "views/admin/controllers/controller";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const {role} = useAppStore();
  const [open, setOpen] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("ETUDIANTS");

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const auth = useAuthUser();
  const { setRole,setUser } = useAppStore();
  const qk = ["auth", auth?.id];
  const {data} = useQuery({
    queryKey:qk,
    queryFn:() => getAuth(auth?.id), 
    staleTime:1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if(data){
      setRole(data.role);
      setUser(data);
      }
  } ,[data])

  const getActiveRoute = (routes) => {
    let activeRoute = "ETUDIANTS";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if ((prop.layout === "/admin") && (role?.includes(prop.role))) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"E-CAMPUS"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
              <Route element={<AuthOutlet fallbackPath='/auth' />}>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" />}
                />
                {role.includes('admin') && <Route
                  path="/etudiants/:id"
                  element={<Etudiant />}
                />}
               {role.includes('admin') && <Route
                  path="/comptes/:id"
                  element={<Compte />}
                />}
                {role.includes('controleur') && <Route
                  path="/controllers/:id"
                  element={<Controller />}
                />}
                </Route>
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
