import React from 'react'
import { Document,Font,Image,Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { createTw } from "react-pdf-tailwind";
Font.register({
  family: 'Bebas Neue',
  src:'/Bebas_Neue/BebasNeue-Regular.ttf'
})
const tw = createTw({
  theme: {
    fontFamily: {
      sans: ["Comic Sans"],
    },
    extend: {
      fontFamily: {
        'roboto': ['"Roboto Serif"', "serif"],
      },
    },
  },
});

const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
});





function Recto({compte}) {
  return (
    <Document>
    <Page size={[285, 175]} style={tw('flex')} >
    <Image src="/bg_recto.png" style={styles.pageBackground} />
        <View style={tw("flex flex-col items-center justify-between w-full h-full")}>
        <View style={tw("flex flex-row items-center justify-between w-full")}>
            <View style={tw('flex flex-col' )}>
              <View style={tw('flex flex-col items-center justify-center' )}>
              <View style={tw('flex flex-row justify-between p-2')}>
              <Image src="/logo.png" style={tw('w-16 h-16')} />
              <View style={tw("flex flex-col items-center")}>
                <View>
                  <Text style={{fontFamily:'Bebas Neue',fontSize:'10px'}}> République du Sénégal</Text>
                </View>
                <View  style={tw('font-semibold')}>
                <Text style={{fontFamily:'Bebas Neue',fontSize:'8px'}}> Un peuple - Un but - Une foi</Text>
              </View>
              <View  style={tw("text-center font-semibold")}>
                <Text style={{fontFamily:'Bebas Neue',fontSize:'7px',textAlign:"center"}}>Ministère de l'Enseignement Supérieur, de la Recherche et de
                l'Innovation </Text> 
              </View>
              <View  style={tw('flex flex-col items-center justify-center')} >
              <Text style={{fontFamily:'Bebas Neue',fontSize:'8px',textAlign:'center'}}>Centre Régional des Oeuvres Universitaires Sociales de
                Ziguinchor </Text>
                <Text style={{fontFamily:'Bebas Neue',fontSize:'8px',textAlign:'center'}}>(CROUS/Z)</Text>  
              </View>
             </View>
              <Image src="/drapeau.png" style={tw('w-10 h-14 object-cover')}/>
        </View>
        <View style={tw('flex flex-row items-center justify-center w-10/12 mx-auto' )}>
              <Text style={{fontFamily:'Bebas Neue',fontSize:'25px',textAlign:"center",color:'#2290f0'}}>CARTE SOCIALE</Text>
            </View>
        <View style={tw('flex flex-row p-2 w-full')}>

        <View style={tw("rounded-full h-24 w-24 border border-2 border-cyan-500 ml-4 mr-4")}>
                <Image
                              src={{uri:`${process.env.REACT_APP_BACKURL_ETUDIANT}/${compte?.etudiant.avatar}`}}
                              style={tw('rounded-full object-center object-cover h-24 w-24')}
                            />
          </View>
        <View>
        
          <View>
               <Text style={{fontFamily:'Bebas Neue',fontSize:'14px',textAlign:'center'}}>{compte?.etudiant.prenom} {compte?.etudiant.nom} </Text>
              </View>
              <View style={tw('flex flex-row items-center')}>
              <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}> N°:</Text>
                <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}> {compte?.etudiant.ncs} </Text>
              </View>
              <View style={tw('flex flex-row items-center')}>
              <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}> Nationalite:</Text>

                <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}> {compte?.etudiant.nationalite} </Text>
              </View>
              <View style={tw('flex flex-row items-center')}>
              <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}> Tel:</Text>

                <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}>{compte?.etudiant.telephone} </Text>
              </View>
              <View style={tw('flex flex-row items-center')}>
              <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}> Adresse:</Text>

                <Text style={{fontFamily:'Bebas Neue',fontSize:'10px',textAlign:'center',marginLeft:'2px'}}>{compte?.etudiant.adresse} </Text>
              </View>
        </View>     
        </View>

        
             
             </View>
              </View>
           
            </View>
          </View>
       
    </Page>
   
  </Document>
  )
}

export default Recto