import Depots from "./components/depots"
import Retraits from "./components/retraits"

function Surveil() {

  return (
    <div className="space-y-10">
     <Depots />
     <Retraits />
    </div>
  )
}

export default Surveil