import { useEffect, useState} from 'react';
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom'
import { format} from 'date-fns';
import { OperationService } from 'services/operation.service';
import { DataTable } from 'mantine-datatable';
import { useDebouncedValue } from '@mantine/hooks';
import { LoadingOverlay } from '@mantine/core';
import InputField from 'components/fields/InputField';
import { sortBy } from 'lodash';
import { FaSortAlphaDown, FaSortAlphaDownAlt } from 'react-icons/fa';


const PAGE_SIZE = 15;
function Compte() {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [sortStatus, setSortStatus] = useState({
        columnAccessor: 'type',
        direction: 'asc',
      });
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState([]);
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const qk = ['getOperations',id];
    const operationService = new OperationService();

    const { data: operations,isLoading } = useQuery({
        queryKey: qk,
        queryFn: () => operationService.byCompte(id),
    })

    const filtered = (operations = []) => {
        return  operations?.filter(({ montant }) => {
          if (
            debouncedQuery !== '' &&
            !`${montant}`.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
          )
            return false;
    
          return true;
        })
      }
    
      useEffect(() => {
         if(debouncedQuery === ''){
          if(searchParams.has('page')){
            setPage(parseInt(searchParams.get('page')));
          }else {
            setPage(1);
          }
         }
         else {
          setPage(1);
         }
          
          const from = (page - 1) * PAGE_SIZE;
          const to = from + PAGE_SIZE;
          const data = sortBy(operations, sortStatus.columnAccessor);
        setRecords(sortStatus.direction === 'desc' ? (filtered(data).slice(from, to) ?? []).reverse() : filtered(data).slice(from, to) ?? []);
        }, [searchParams,page,operations,debouncedQuery,sortStatus]);
  return (
    <div>
    <LoadingOverlay
       visible={isLoading}
       zIndex={1000}
       overlayProps={{ radius: 'sm', blur: 2 }}
       loaderProps={{ color: 'blue', type: 'dots' }}
     />
   
    <div className="mt-5">
   
      <WeeklyRevenue>
      <>
      <div className="flex justify-between items-center w-1/2">
      <div className="w-full">
             <InputField value={query} onChange={(e) => setQuery(e.currentTarget.value)}  placeholder="Rechercher ..." />
       </div>
     </div>
      <DataTable
    columns={[
      
      { accessor: 'Date',textAlign: 'center', render:({createdAt}) => format(createdAt,'dd/MM/yyyy') },
      { accessor: 'heure',textAlign: 'center', render:({createdAt}) => format(createdAt,'HH:mm:ss')},
        { accessor: 'type',textAlign: 'center',sortable:true }, 
      {
        accessor: 'montant',
        textAlign: 'center',
        sortable:true
      }
  ]}
    records={records}
    idAccessor="_id"
    fetching={isLoading}
    totalRecords={filtered(operations)?.length}
    recordsPerPage={PAGE_SIZE}
    page={page}
    onPageChange={(p) => {
      setSearchParams({'page':p});
      setPage(p);
    }
    }
    sortStatus={sortStatus}
    onSortStatusChange={setSortStatus}
    sortIcons={{
      sorted: <FaSortAlphaDownAlt size={14} />,
      unsorted: <FaSortAlphaDown size={14} />,
    }}
    borderRadius="lg"
    shadow="lg"
    horizontalSpacing="xs"
    verticalAlign="top"
  />
      </>
      
      </WeeklyRevenue>
    </div>
  </div>
  )
}

export default Compte